




















































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Action, Getter, State} from 'vuex-class';
import _ from 'lodash';
import moment from 'moment';
import {Product, Cms, Proposal, App} from '@/models';

import ProductComponentConfig from './ProductComponentConfig/Index.vue';
import PolicyCoverage from '../../PolicyCoverage.vue';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import { IQuotationProduct, IShiftProductData, ISetPolicyStartDatePayload, IRawProducts } from '@/interfaces';
import VueMarkdown from 'vue-markdown';

@Component({
  name: 'Product',
  components: {
    ProductComponentConfig,
    PolicyCoverage,
    VueMarkdown
  }
})
export default class extends Vue {
  @Prop() private product!: IShiftProductData;
  @Prop() private isPivotCoverage!: boolean;
  @Prop() private isCoverageDisabled!: boolean;
  @Prop() private icon!: string;
  @State private cms!: Cms;
  @State private app!: App;
  @State private proposal!: Proposal;
  @State('products') private rawProducts!: IRawProducts;
  @Action('app/setValue') private setValue: any;
  @Action('proposal/removeProduct') private removeProduct: any;
  @Action('quotation/deleteQuotationProduct') private deleteQuotationProduct!: (productID: string) => void;
  @Action('quotation/setPolicyDate') private setPolicyDate!: (payload: ISetPolicyStartDatePayload) => void;
  @Getter('cms/getProductContentByProduct') private getProductContentByProduct: any;
  @Getter('cms/getProductContentsByProduct') private getProductContentsByProduct: any;
  @Getter('proposal/getSelectedProducts') private getSelectedProducts: any;
  @Getter('cms/getComponentImportantInformation') private getComponentImportantInformation: any;
  @Getter('quotation/getSortedQuotationProduct') private getSortedQuotationProduct!: () => IShiftProductData[];
  @Getter('quotation/hasPastCoverageStartDateForProductId') private hasPastCoverageStartDateForProductId: any;

  get products() {
    return this.getSortedQuotationProduct();
  }

  get filteredProducts() {
    return _.filter(this.products, (p: IShiftProductData) => (!_.values(_.get(this.app, 'renewalResponse.renewalIds', {})).includes(p.id)) );
  }

  private firstLoad = true;

  private importantInformation: string = '';
  private componentImportantInformation: any = {};
  private content: any;

  private minDate: string = '';
  private maxDate: string = '';

  private currentPrice: any = 0;
  private reviewRequired = false;
  private productTree = {components: []};
  private productCoverage: {policyStartDate, policyEndDate} = {policyStartDate: '', policyEndDate: ''};

  public validate() {
    let validationResult = true;
    _.forEach(this.$refs.components, (comp: any) => {
      validationResult = validationResult && comp.validate();
    });
    return validationResult;
  }

  get hasDetails() {
    return !this.content.catalogueDetailsOnly &&
      (this.content && this.getProductContentsByProduct(this.product).length === 1 &&
      (this.content.details && !Util.isEmptyString(this.content.details.replace(/(<([^>]+)>)/ig, ''))) ||
      !_.isEmpty(this.content.additionalInformation) ||
      !_.isEmpty(this.content.questionnaire));
  }

  get showDatesSection() {
    return _.get(this.app, 'showDatesSection', false) && !_.get(this.app, 'isRenewal');
  }

  private async created() {

    // renewal link with a 'new business' product with a coverage start date in the past
    const nonRenewalIds = _.values(_.get(this.app, 'renewalResponse.nonRenewalIds', {}));
    if (this.app.isRenewal && nonRenewalIds.includes(this.product.id) && this.hasPastCoverageStartDateForProductId(this.product.id)) {
      this.$dialog.open({ type: 'past-coverage-start-date', info: 'past-coverage-start-date' });
      this.setCoverageStartDateToday(this.product.id);
    }

    this.content = this.getProductContentByProduct(this.product);
    this.importantInformation = _.get(this.content, 'importantInformation', '');
    if (_.get(this.content, 'componentImportantInformation', [])) {
      this.componentImportantInformation = await this.getComponentImportantInformation(_.get(this.content, 'componentImportantInformation', []));
    }
    this.minDate = moment().startOf('day').toISOString();
    this.maxDate = moment().startOf('day').add(60, 'days').toISOString();

    if (this.firstLoad) {
      this.firstLoad = false;
    }
  }

  private setCoverageStartDateToday(productID: any) {
      this.setPolicyDate({productID, date: moment().format('YYYY-MM-DD')});
  }

  get isSeparateCoverage() {
    return Util.isSeparateCoveragePeriod(this.product.code);
  }

  get isCustomCoverage() {
    return _.get(this.app.config.shiftMappings, 'CUSTOM_COVERAGE_PRODUCTS', {});
  }

  private shouldDisplay(comp: any) {
    return !!_.get(comp, 'allFacts.length') || _.get(comp, 'insuredTypes[0].minAllowed') !== _.get(comp, 'insuredTypes[0].maxAllowed');
  }

  private getCurrentNumber(index: number) {
    let result = (this.product.isSeparateCoveragePeriod || this.showDatesSection) ? 1 : 2;
    for (const c of this.product.customProductTree.components) {
      if (index-- === 0) {
        return result;
      }
      if (this.shouldDisplay(c)) {
        result++;
      }
    }
    return result;
  }

  private onCoverageStartDateStrInput(value: string) {
    this.setPolicyDate({productID: this.product.id, date: value });
  }

  private onPolicyCoverageErrorChange(value: boolean) {
    EventBus.$emit('policyCoverageError', this.product.code, value);
  }

  private copyCoverageDateToAll(value) {
    this.setValue({code: 'copyCoverageDate', value: !!value});
    if (value) {
      this.$emit('disableCoverage');
    } else {
      this.$emit('enableCoverage');
    }
  }

  private getIcon() {
    return _.get(this.cms, 'theme.information', '');
  }

  private getText() {
    return this.importantInformation;
  }

  private getTextComponent(code) {
    return Util.stringToHtml(_.get(this.componentImportantInformation, code, ''));
  }

  private hasRequiredError(errors, factId) {
   return  _.some(errors, (err) => {
     return err.error.code === 1 && _.includes(err.error.relatedFacts, factId);
    });
  }

  private getComponentPrice(index: number) {
    try {
      const componentPrices = _.get(this.product.customDescription, `calculation.price.componentResult[${index}]`, {});
      const isInsuredPricesExist = componentPrices && componentPrices.insuredResult.length > 0;
      const priced = _.get(this.product.customDescription, `description.product.components[${index}].priced`, false);
      return {
        ...componentPrices,
        ...{ priced: isInsuredPricesExist ? true :  priced}
      };
    } catch (error) {
      return {};
    }
  }

  private removeProductConfirmation() {
    this.$dialog.open({
      icon: _.get(this.cms, 'theme.deleteIcon'),
      text: this.getMessage,
      buttons: [
        {
          text: this.$t('button.cancel')
        },
        {
          type: 'main',
          text: this.$t('button.remove'),
          onClick: this.removeProductAction
        }
      ]
    });
  }

  private removeProductAction() {
    Util.gaLogEcommerceEvent(this, 'click', 'remove_from_cart', this.product);
    this.removeProduct({productID: this.product.id});
    this.deleteQuotationProduct(this.product.id);
  }

  private onInfoBtnClick() {
    this.$emit('onInfoBtnClick', this.content);
  }

  get getMessage() {
    return (this.$t('product.configuration.remove', {name: this.content.title}));
  }
}
